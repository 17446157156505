/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $FiservGooglePay = {
    description: `If you have this object in reply that's mean fiserv google pay card processing is enable on backend side. For the initialization of Google Pay, we need to pass fields from the response to the Google Pay react component.  In our case, we use '@google-pay/button-react'. Data from the response we provide to these fields:  tokenizationSpecification: {
        type: 'PAYMENT_GATEWAY',
        parameters: {
            gateway: config?.gateway as string,
            gatewayMerchantId: config?.merchant as string,
        },
    },
    merchantInfo: {
        merchantId: config?.merchantId as string,
        merchantName: config?.merchantName?.replace(/_/g, ' '),
    },
    For more information about initialization Google Pay button you can get from this resources: https://www.npmjs.com/package/@google-pay/button-react'
    `,
    properties: {
        gateway: {
            type: 'string',
            isRequired: true,
        },
        merchant: {
            type: 'string',
            isRequired: true,
        },
        merchantId: {
            type: 'string',
            isRequired: true,
        },
        merchantName: {
            type: 'string',
            isRequired: true,
        },
        providerName: {
            type: 'string',
            isRequired: true,
        },
    },
} as const;
