import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { OpenSnackbarOptions } from '@patrianna/shared-patrianna-types/store/SnackbarModule'
import type { SnackbarState } from 'store/types/SnackbarModule'

const initialState: SnackbarState = {
  visible: false,
  message: '',
  action: undefined,
  autoHide: undefined,
  messageAlign: undefined,
  closeWhenPathChanged: true,
}

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    openSnackbar: (state, action: PayloadAction<OpenSnackbarOptions>) => {
      const defaultOptions: OpenSnackbarOptions = {
        message: '',
        componentProps: {},
        autoHide: 4000,
        variant: 'error',
        messageAlign: 'flex-start',
        positionVertical: 'bottom',
        positionHorizontal: 'center',
        closeWhenPathChanged: true,
      }

      return {
        ...defaultOptions,
        ...action.payload,
        visible: true,
      }
    },

    closeSnackbar: (state) => {
      state.visible = false
    },
    clearSnackbarState: () => initialState,
  },
})

export type ActionsTypes = ReturnType<InferValueTypes<typeof snackbarSlice.actions>>
export const { actions } = snackbarSlice
