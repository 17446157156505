/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Nuvei = {
    description: `ACH provider. Iframe initialization not needed.  On purchase just use redirect url from [Create order](#operation/createOrder). On withdraw just pass ACH data to [Redeem money](#operation/redeemMoney)
    `,
    properties: {
        id: {
            type: 'string',
        },
        providerName: {
            type: 'string',
        },
    },
} as const;
