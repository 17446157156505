/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $FiservApplePay = {
    description: `If you have this object in reply that's mean fiserv apple pay card processing is enable on backend side.`,
    properties: {
        merchantId: {
            type: 'string',
            description: `For the initialization of Apple Pay, we need to pass the merchantId to the initialization function: window.ApplePaySession.canMakePayments(merchantId).`,
            isRequired: true,
        },
        providerName: {
            type: 'string',
            isRequired: true,
        },
    },
} as const;
