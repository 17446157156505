/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum UserPaymentMode {
    CARD = 'card',
    SKRILL = 'skrill',
    WIRE_TRANSFER = 'wire_transfer',
    VIRTUAL_WALLET_APPLE_PAY = 'virtual_wallet_apple_pay',
    VIRTUAL_WALLET_GOOGLE_PAY = 'virtual_wallet_google_pay',
    IN_APP_PURCHASE = 'in_app_purchase',
}
