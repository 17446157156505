/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Spreedly = {
    description: `If you have this object in reply that's mean spreedly card processing is enable on backend side.`,
    properties: {
        id: {
            type: 'string',
            description: `For the initialization of Spreedly, we need to pass the id to the initialization function:
            Spreedly.init('id', {
                'numberEl': 'spreedly-number',
                'cvvEl' 'spreedly-cvv'
            });
            `,
        },
        providerName: {
            type: 'string',
        },
    },
} as const;
