/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Skrill = {
    description: `Digital wallet. If you have this object in reply that's mean skrill processing is enable on backend side. On purchase just use redirect url from [Create order](#operation/createOrder)'
    `,
    properties: {
        id: {
            type: 'string',
        },
        providerName: {
            type: 'string',
        },
    },
} as const;
