/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $StandardAch = {
    description: `Ach provider. If you have this object in reply that's mean standard ach option is enabled on backend side.`,
    properties: {
        providerName: {
            type: 'string',
        },
    },
} as const;
