import gatewayInstance, { api, gatewayErrorInstance, fraud, payment } from 'services/gateway'
import gatewayFetchV2 from '@patrianna/gateway/gatewayFetch/indexV2'

export type ServiceDependenciesOptions = {
  gateway: typeof gatewayInstance
  errorHandler: (typeof gatewayErrorInstance)['errorHandler']
  fetch: typeof gatewayFetchV2
  api: typeof api
  fraud: typeof fraud
  payment: typeof payment
}

/*
  Use this to inject API dependencies to redux-store
  if redux-store ( thunk ) is not available in the context
  use this object directly

  Example:
  import { serviceDependencies } from 'src/services'

  serviceDependencies.gateway.emit<SomeRequest>({ type: 'SomeRequest' })
  serviceDependencies.errorHandler(err, data)

  In the next refactoring, we are going to remove "gatewayFetch" and will use
  "serviceDependencies.fetch" instead
 */
export const serviceDependencies: ServiceDependenciesOptions = {
  gateway: gatewayInstance,
  errorHandler: gatewayErrorInstance.errorHandler.bind(gatewayErrorInstance),
  fetch: gatewayFetchV2,
  api,
  fraud,
  payment,
}
