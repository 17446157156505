/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AppleInApp = {
    description: `If you have this object in reply that's mean apple application purchase processing is enable on backend side.`,
    properties: {
        id: {
            type: 'string',
        },
        providerName: {
            type: 'string',
            isRequired: true,
        },
    },
} as const;
